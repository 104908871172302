import Vuex from 'vuex'
import ui from './modules/ui'
import socket from './modules/socket'
import user from './modules/user'
import resources from './modules/resources'

const modules = {
  ui,
  user,
  socket,
  resources,
}

const store = new Vuex.Store({
  namespaced: true,
  modules,
  strict: process.env.NODE_ENV !== 'production',
})

const createStore = () => {
  return store
}

export { store }
export default createStore
