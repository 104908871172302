import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a68c9838 = () => interopDefault(import('../pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _81c1899a = () => interopDefault(import('../pages/catalog/index.vue' /* webpackChunkName: "pages/catalog/index" */))
const _5996059c = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _e2a59056 = () => interopDefault(import('../pages/invitation/index.vue' /* webpackChunkName: "pages/invitation/index" */))
const _62c4f3c0 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4293cca1 = () => interopDefault(import('../pages/orders/index.vue' /* webpackChunkName: "pages/orders/index" */))
const _48731ab2 = () => interopDefault(import('../pages/partners/index.vue' /* webpackChunkName: "pages/partners/index" */))
const _25ddceb4 = () => interopDefault(import('../pages/payments.vue' /* webpackChunkName: "pages/payments" */))
const _af74b154 = () => interopDefault(import('../pages/reports.vue' /* webpackChunkName: "pages/reports" */))
const _5fb030a9 = () => interopDefault(import('../pages/reset/index.vue' /* webpackChunkName: "pages/reset/index" */))
const _582907c2 = () => interopDefault(import('../pages/settings/index.vue' /* webpackChunkName: "pages/settings/index" */))
const _eed85e80 = () => interopDefault(import('../pages/auth/impersonate-user/index.vue' /* webpackChunkName: "pages/auth/impersonate-user/index" */))
const _a2504442 = () => interopDefault(import('../pages/catalog/library/index.vue' /* webpackChunkName: "pages/catalog/library/index" */))
const _65b42dfc = () => interopDefault(import('../pages/catalog/prices/index.vue' /* webpackChunkName: "pages/catalog/prices/index" */))
const _d0f3d0a0 = () => interopDefault(import('../pages/partners/practices/index.vue' /* webpackChunkName: "pages/partners/practices/index" */))
const _08d2e8aa = () => interopDefault(import('../pages/auth/impersonate-user/_id/index.vue' /* webpackChunkName: "pages/auth/impersonate-user/_id/index" */))
const _2b21b5f3 = () => interopDefault(import('../pages/invitation/_hash/index.vue' /* webpackChunkName: "pages/invitation/_hash/index" */))
const _7b83af0c = () => interopDefault(import('../pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _ea988072 = () => interopDefault(import('../pages/reset/_hash/index.vue' /* webpackChunkName: "pages/reset/_hash/index" */))
const _cdc55eae = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/auth",
    component: _a68c9838,
    name: "auth"
  }, {
    path: "/catalog",
    component: _81c1899a,
    name: "catalog"
  }, {
    path: "/forgot-password",
    component: _5996059c,
    name: "forgot-password"
  }, {
    path: "/invitation",
    component: _e2a59056,
    name: "invitation"
  }, {
    path: "/login",
    component: _62c4f3c0,
    name: "login"
  }, {
    path: "/orders",
    component: _4293cca1,
    name: "orders"
  }, {
    path: "/partners",
    component: _48731ab2,
    name: "partners"
  }, {
    path: "/payments",
    component: _25ddceb4,
    name: "payments"
  }, {
    path: "/reports",
    component: _af74b154,
    name: "reports"
  }, {
    path: "/reset",
    component: _5fb030a9,
    name: "reset"
  }, {
    path: "/settings",
    component: _582907c2,
    name: "settings"
  }, {
    path: "/auth/impersonate-user",
    component: _eed85e80,
    name: "auth-impersonate-user"
  }, {
    path: "/catalog/library",
    component: _a2504442,
    name: "catalog-library"
  }, {
    path: "/catalog/prices",
    component: _65b42dfc,
    name: "catalog-prices"
  }, {
    path: "/partners/practices",
    component: _d0f3d0a0,
    name: "partners-practices"
  }, {
    path: "/auth/impersonate-user/:id",
    component: _08d2e8aa,
    name: "auth-impersonate-user-id"
  }, {
    path: "/invitation/:hash",
    component: _2b21b5f3,
    name: "invitation-hash"
  }, {
    path: "/orders/:id",
    component: _7b83af0c,
    name: "orders-id"
  }, {
    path: "/reset/:hash",
    component: _ea988072,
    name: "reset-hash"
  }, {
    path: "/",
    component: _cdc55eae,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
