import actions from './actions'
import mutations from './mutations'

const resources = {
  state: {
    practiceLocationsLight: [],
  },
  getters: {
    practiceLocationsLight: (state) => state.practiceLocationsLight,
  },
  mutations,
  actions,
  namespaced: true,
}

export default resources
