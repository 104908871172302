import * as types from './mutation-types'
import { getBaseUrlNewApi } from '~/config/api'

export default {
  async FetchPracticeLocationsLight() {
    try {
      return (await this.$axios.get(`${getBaseUrlNewApi()}/practice-location/get-practice-location-options?with_buying_group=1`)).data
    } catch (e) {
      console.error('FetchPracticeLocationsLight: ', e)
    }
  },
  async SetPracticeLocationsLight({ commit, dispatch, getters }) {
    try {
      if (getters.practiceLocationsLight.length) return

      commit(
        types.SET_PRACTICE_LOCATIONS__LIGHT,
        await dispatch('FetchPracticeLocationsLight')
      )
    } catch (e) {
      console.error('SetPracticeLocationsLight: ', e)
    }
  },
}
